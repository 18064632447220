<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <query-box
            class="second-header"
            top=".89rem"
            icon="&#xf00d;"
            placeholder="请输入礼品卡号"
            search-btn-str="绑定"
            show-search-btn
            show-reset-btn
            v-model="cardSn"
            @query="onConvertCoupon"
        />
        <tabs
            class="average second-header"
            @tabChange="onTabsChange"
        >
            <tab-panel
                :label="`可用卡${type===0?`(${pagination.totalRows||0})`:''}`"
                :sign="0"
                :active-panel="type===0"
            />
            <tab-panel
                :label="`不可用卡${type===1?`(${pagination.totalRows||0})`:''}`"
                :sign="1"
                :active-panel="type===1"
            />
        </tabs>
        <container ref="container">
            <list>
                <GiftCardItem
                    v-for="(item) in listData"
                    :key="item.id"
                    :data="item"
                />
            </list>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'PointList',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            listData: [],
            cardSn: '20210701171828082777680',
        };
    },
    computed: {
        /**
         * 1未使用 2已使用 3已过期
         * @returns {number}
         */
        type() {
            return +this.$route.params.type;
        },
    },
    methods: {
        getListData({ type = this.type, currentPage, pageSize } = {}) {
            return this.$api.Buyer.Mb.GiftCard.giftCardList({
                isAllUse: type,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.listData = this.listData.concat(res.data);
                return Promise.resolve({ data: res });
            });
        },
        onTabsChange(item) {
            this.$router.replace({
                path: `./${item.sign}`,
                query: {
                    ...this.$router.query,
                },
            });
        },
        refreshData() {
            this.infiniteDisabled = true;
            this.pagination = {};
            this.listData = [];
            this.$nextTick(() => (this.infiniteDisabled = false));
        },
        onConvertCoupon() {
            const { cardSn } = this;
            if (!cardSn) return;
            console.log(cardSn);
            this.$api.Buyer.Mb.GiftCard.bind({
                cardSn,
            }).then(json => {
                const result = json.data;
                if (!result.success) {
                    this.$messageBox.tips(result.msg, 3000);
                    return;
                }
                this.$messageBox.alert('<div class="ta-c">绑定成功</div>', '温馨提示').then(() => {
                    this.refreshData();
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
