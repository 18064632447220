<!-- Created by henian.xu on 2018/11/22. -->

<template>
    <Page>
        <Container ref="container">
            <XForm
                ref="form"
                :model="formModel"
                :rules="formRules"
            >
                <FormItem
                    prop="userAvatar"
                    label="头像"
                >
                    <Upload
                        :column="3"
                        :max-files="1"
                        v-model="formModel.userAvatar"
                        action="/rpc/comm/rs/attachment/uploadUserAvatarImage"
                    />
                </FormItem>
                <FormItem
                    prop="nickName"
                    label="昵称:"
                >
                    <XInput v-model="formModel.nickName" />
                </FormItem>
            </XForm>
        </Container>
        <PageBar>
            <Button
                theme="main"
                label="提交"
                @click="onSubmit"
            />
        </PageBar>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
export default {
    name: '',
    mixins: [pageMixin],
    data() {
        return {
            formModel: {
                userAvatar: [],
                nickName: '',
            },
            formRules: {
                userAvatar: {
                    type: 'array',
                    min: 1,
                    required: true,
                    message: '头像不能为空',
                },
                nickName: {
                    required: true,
                    message: '昵称不能为空',
                },
            },
        };
    },
    methods: {
        onSubmit() {
            this.$refs['form'].validate().then(() => {
                const { formModel } = this;
                const data = { ...formModel };
                data.userAvatar = formModel.userAvatar.reduce((p, c) => (p.push(c.name), p), []).join(',');
                this.$api.Buyer.Mb.Buyer.editUserInfo(data).then(() => {
                    this.updateUserData({
                        userAvatar: formModel.userAvatar[0].address,
                        nickName: data.nickName,
                    });
                    this.$router.go(-1);
                });
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const { formModel, userData } = vm;
            if (userData.userAvatar) {
                formModel.userAvatar = [
                    {
                        name: '',
                        address: userData.userAvatar,
                    },
                ];
            }
            if (userData.nickName) {
                formModel.nickName = userData.nickName;
            }
        });
    },
};
</script>

<style lang="scss">
</style>
