<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page class="bc-w">
        <container ref="container">
            <user-box
                class="nbor-a ma-b"
                :data="buyerData"
            />

            <!--<div class="tool-bar ma-b bor-t">
                <linker
                    to="/buyer/pointList"
                    class="item">
                    <div class="num tc-main">{{ buyerData.validPoint || 0 }}</div>
                    <div class="label">
                        <i class="f-icon tc-main">&#xf00c;</i>
                        我的积分
                    </div>
                </linker>
                <linker
                    to="/buyer/couponList/1"
                    class="item">
                    <div class="num tc-main">{{ buyerData.couponNum || 0 }}</div>
                    <div class="label">
                        <i class="f-icon tc-main">&#xf00d;</i>
                        我的优惠券
                    </div>
                </linker>
            </div>-->

            <list>
                <list-item to="/order/list">
                    <div
                        class="label fw-bold fs-big"
                        slot="label"
                    >
                        我的订单
                    </div>
                    <div
                        class="extra fs-little tc-g6"
                        slot="extra"
                    >
                        查看全部订单
                    </div>
                </list-item>
            </list>
            <cube-toolbar class="border-cube-toolbar small ma-b">
                <linker
                    to="/order/list/10"
                    class="item"
                >
                    <XIcon
                        content="f130"
                        :badge="orderCount.waitPayNum"
                    />
                    <div class="label">
                        待付款
                    </div>
                </linker>
                <linker
                    v-if="$hasPower(['groupBuy:use'])"
                    to="/order/list/15"
                    class="item"
                >
                    <XIcon
                        content="f13c"
                        :badge="orderCount.groupBuyNum"
                    />
                    <div class="label">
                        待拼团
                    </div>
                </linker>
                <linker
                    to="/order/list/20"
                    class="item"
                >
                    <XIcon
                        content="f132"
                        :badge="orderCount.waitShipNum"
                    />
                    <div class="label">
                        待发货
                    </div>
                </linker>
                <linker
                    to="/order/list/30"
                    class="item"
                >
                    <XIcon
                        content="f134"
                        :badge="orderCount.waitRecNum"
                    />
                    <div class="label">
                        待收货
                    </div>
                </linker>
                <!--这里的权限是因为放置的位置有差异-->
                <!--v-if="$hasPower(['distributorEntrance:entrance'])"-->
                <linker
                    class="item npa-l npa-r"
                    to="/refund/list"
                >
                    <XIcon
                        content="f124"
                        :badge="orderCount.waitRefundNum"
                    />
                    <div class="label">
                        退款/售后
                    </div>
                </linker>
                <!--<linker
                    v-else
                    to="/review/list/10"
                    class="item"
                >
                    <XIcon
                        content="f136"
                        :badge="orderCount.waitReview"
                    />
                    <div class="label">
                        待评价
                    </div>
                </linker>-->
            </cube-toolbar>

            <list>
                <list-item>
                    <div
                        class="label fw-bold fs-big"
                        slot="label"
                    >
                        我的服务
                    </div>
                </list-item>
            </list>
            <GridGroup
                :columns="4"
                class="bc-w ma-b"
                :grid-line="false"
            >
                <!--<Grid label="label">
                    <icon
                        class="item"
                        icon-cls="fs-more"
                        code="&#xf006;"
                        column
                        slot="icon"
                    />
                </Grid>-->
                <!--<Grid
                    icon-class="tc-second fs-super"
                    icon="&#xf03c;"
                    label="我的零钱"
                    to="/pocketMoney/index">
                    <div
                        class="sub-label price fs-normal"
                        slot="subLabel">{{ buyerData.purseBalance }}</div>
                </Grid>-->
                <!--<Grid
                    icon-class="tc-second fs-super"
                    icon="&#xf00c;"
                    label="我的积分"
                    to="/buyer/pointList"
                >
                    <div
                        class="sub-label tc-price fs-normal"
                        slot="subLabel"
                    >
                        {{ buyerData.validPoint || 0 }}
                    </div>
                </Grid>
                <Grid
                    v-if="$hasPower(['coupon:use'])"
                    icon-class="tc-second fs-super"
                    icon="&#xf00d;"
                    label="我优惠券"
                    to="/buyer/couponList/1"
                >
                    <div
                        class="sub-label tc-price fs-normal"
                        slot="subLabel"
                    >
                        {{ buyerData.couponNum || 0 }}
                    </div>
                </Grid>-->
                <Grid
                    label="地址管理"
                    @click="onAddressManage"
                >
                    <XIcon
                        slot="icon"
                        content="f137"
                        size="130%"
                        svg
                    />
                    <address-picker
                        v-show="false"
                        ref="addressPicker"
                        type="manage"
                    />
                </Grid>


                <Grid
                    label="我的收藏"
                    to="/buyer/collect"
                >
                    <XIcon
                        slot="icon"
                        content="f108"
                        size="130%"
                        svg
                    />
                </Grid>
                <Grid
                    label="我的足迹"
                    to="/buyer/footprint"
                >
                    <XIcon
                        slot="icon"
                        content="f120"
                        size="130%"
                        svg
                    />
                </Grid>
                <template v-if="distributionIncomeMode">
                    <Grid
                        v-if="$hasPower(['distributorEntrance:entrance'])"
                        label="我的代理"
                        to="/distributor/home"
                    >
                        <XIcon
                            slot="icon"
                            content="f14c"
                            size="130%"
                            svg
                        />
                    </Grid>
                    <Grid
                        label="售后须知"
                        to="/afterSalesNotice"
                    >
                        <XIcon
                            slot="icon"
                            content="f139"
                            size="130%"
                            svg
                        />
                    </Grid>
                </template>
                <Grid
                    v-if="meiQiaAllSet"
                    label="在线客服"
                    @click="onService"
                >
                    <XIcon
                        slot="icon"
                        content="f128"
                        size="130%"
                        :badge="unreadMsgCount"
                        svg
                    />
                </Grid>
                <!--<Grid
                    v-if="!$hasPower(['distributorEntrance:entrance'])"
                    label="退款/售后"
                    to="/refund/list"
                >
                    <XIcon
                        slot="icon"
                        content="f124"
                        size="130%"
                        :badge="orderCount.waitRefundNum"
                        svg
                    />
                </Grid>-->
                <Grid
                    v-if="$hasPower(['distributorShopStore:use']) && $globalVar.userData.shopStoreType===2"
                    label="店铺管理"
                    :to="`/proxyStore/${$globalVar.userData.storeUserName}/home`"
                >
                    <XIcon
                        slot="icon"
                        content="f11d"
                        size="130%"
                        svg
                    />
                </Grid>
                <Grid
                    v-if="$hasPower(['buyerCenter:batchCreateOrder:use'])"
                    label="批量导单"
                    to="/distributor/orderImport"
                >
                    <XIcon
                        slot="icon"
                        content="f113"
                        size="130%"
                        svg
                    />
                </Grid>
                <Grid
                    v-if="$hasPower(['buyerCenter:offlinePaymentList:use'])"
                    label="线下付款"
                    to="/buyer/offlinePaymentList"
                >
                    <XIcon
                        slot="icon"
                        content="f13d"
                        size="130%"
                        svg
                    />
                </Grid>
                <Grid
                    v-if="+$globalVar.memberRegisterMode===2"
                    icon-class="tc-main fs-super"
                    icon="&#xf01e;"
                    label="修改密码"
                    to="/buyer/changePassword"
                >
                    <XIcon
                        slot="icon"
                        content="f10c"
                        size="130%"
                        svg
                    />
                </Grid>
                <Grid
                    v-if="+$globalVar.memberRegisterMode!==1"
                    label="退出登录"
                    @click="onLogout"
                >
                    <XIcon
                        slot="icon"
                        content="f100"
                        size="130%"
                        svg
                    />
                </Grid>
            </GridGroup>

            <!--<list class="ma-b">
                <list-item
                    iconClassName="tc-blue"
                    icon="&#xf072;"
                    label="手机"
                    :extra="buyerData.mobile"
                    :to="`tel:${buyerData.mobile}`"
                    noArrow/>
            </list>-->

            <!--<list class="ma-b">
                <list-item
                    to="/pocketMoney/index"
                    class="link"
                    icon-class-name="tc-second"
                    label="我的零钱"
                    icon="&#xf03c;"/>
                <list-item
                    class="link"
                    icon-class-name="tc-red"
                    icon="&#xf00a;">
                    <div
                        class="label lh-normal"
                        slot="label">
                        <address-picker
                            type="manage"
                            label="收货地址管理"/>
                    </div>
                </list-item>
                &lt;!&ndash;<list-item @click="onTest" label="测试"/>&ndash;&gt;
            </list>
            <list class="ma-b">
                <list-item
                    to="/buyer/collect"
                    icon="&#xf03b;"
                    icon-class-name="tc-main"
                    label="我的收藏"/>
                <list-item
                    to="/buyer/footprint/"
                    icon="&#xf073;"
                    icon-class-name="tc-blue"
                    label="我的足迹"/>
            </list>
            <list
                class="ma-b"
                v-if="distributionIncomeMode">
                <list-item
                    v-if="isDistributor"
                    to="/distributor/home"
                    icon="&#xf074;"
                    icon-class-name="tc-second"
                    label="我的代理"/>
                <list-item
                    v-if="isDistributor"
                    to="/afterSalesNotice"
                    icon="&#xf069;"
                    icon-class-name="tc-blue"
                    label="售后须知"/>
                    &lt;!&ndash;<list-item
                    v-else
                    to="/distributor/register"
                    icon="&#xf074;"
                    icon-class-name="tc-second"
                    label="成为分销商"/>&ndash;&gt;
                    &lt;!&ndash;<list-item
                    to="/store/nearby"
                    icon="&#xf023;"
                    iconClassName="tc-second"
                    label="附近门店"
                />&ndash;&gt;
            </list>
            <list
                class="ma-b"
                v-if="+$globalVar.memberRegisterMode===2">
                <list-item
                    icon-class-name="tc-main"
                    icon="&#xf01e;"
                    label="修改密码"
                    to="/buyer/changePassword"/>
                <list-item
                    style="cursor: pointer;"
                    icon-class-name="tc-red"
                    icon="&#xf071;"
                    label="退出登录"
                    @click="onLogout"/>
            </list>-->

            <!-- 以下为开发测试代码 -->
            <!--<upload
                :size="1024*1024"
                :maxFiles="2"
                :column="4"
                v-model="files"
            />-->
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import meiQiaMixin from '@/mixin/meiQiaMixin';
import MeiQiaControl from '@/store/meiQia/MeiQiaControl';

export default {
    name: 'MyHome',
    mixins: [pageMixin, meiQiaMixin],
    data() {
        return {
            region: {},
            buyerData: {},
            distributor: {},
            files: [],
        };
    },
    methods: {
        onTest() {
            this.$messageBox.alert('123', 'abc');
        },
        onAddressManage() {
            console.log(123123);
            this.$refs['addressPicker'].open();
        },
        getBuyerDetail() {
            return this.$api.Buyer.Mb.Buyer.detail().then(json => {
                const res = json.data;
                this.buyerData = {
                    income: 0,
                    ...this.buyerData,
                    ...res.data,
                };
                this.updateUserData({
                    userAvatar: this.buyerData.avatar,
                    nickName: this.buyerData.nickName,
                    userName: this.buyerData.userName,
                    levelIconUrl: this.buyerData.levelIconUrl,
                });
            });
        },
        onLogout() {
            this.$messageBox.confirm('您是否确认退出!').then(() => {
                this.$api.Buyer.Mb.Buyer.logout().then(json => {
                    const res = json.data;
                    this.$messageBox
                        .alert(res.msg, '温馨提示')
                        .then(() => {
                            window.location.href = window.location.href.split('#')[0];
                        })
                        .catch(() => {
                            window.location.href = window.location.href.split('#')[0];
                        });
                });
            });
        },
        onService() {
            if (!this.$globalVar.userData.id) {
                this.$router.replace('/buyer/login');
                return;
            }
            MeiQiaControl.showPanel();
        },
    },
    created() {
        this.getBuyerDetail();
    },
    activated() {
        this.updatedCountInfo();
    },
};
</script>

<style lang="scss">
.money-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $padding * 2 $padding;
    background-color: #fff;
    // min-height: (240vw / 750 * 100);
    min-height: 2.4rem;
    //margin-bottom: $margin-big;
    //border-bottom: 1px solid $color-border;

    > .label {
        color: $gray6;
    }
    > .money {
        color: $color-price;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2;
    }
    > .level {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        color: $color-main;
        margin-top: $margin-big;
        margin-bottom: $margin-big;

        .level-icon {
            width: 1.04rem;
            height: 1.04rem;
            overflow: hidden;
            border-radius: 50%;
            // border: 1px solid $color-main;
        }
    }
    > .ctrl {
        align-self: flex-end;
    }
}
</style>
