<!-- Created by henian.xu on 2018/9/12. -->

<template>
    <Page :style="pageStyles">
        <query-box
            class="second-header"
            top=".89rem"
            placeholder="请输入商品名称"
            show-search-btn
            show-reset-btn
            @input="(val)=>{this.keywords=val}"
            @query="onSearch"
        />
        <container ref="container">
            <Widget
                @decorationData="onDecorationData"
                v-if="decorationJson"
                :json="decorationJson"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
export default {
    name: 'Decoration',
    mixins: [pageMixin],
    data() {
        return {
            keywords: '',
            decorationJson: '',
            pageInfo: {},
        };
    },
    head() {
        const meta = this.$route.meta;
        const { pageTitle } = this;
        if (pageTitle) {
            this.$bus.$emit('pageTitle', pageTitle);
        }
        return {
            title: pageTitle || `${meta.title || ''}${meta.subTitle ? ` - ${meta.subTitle}` : ''}`,
        };
    },
    computed: {
        pageTitle() {
            const { pageInfo } = this;
            const { props } = pageInfo;
            if (!props || !props.title) return '';
            return props.title;
        },
        pageStyles() {
            const { pageInfo } = this;
            const { props } = pageInfo;
            if (!props || !props.backgroundColor) return {};
            return {
                'background-color': props.backgroundColor,
            };
        },
    },
    methods: {
        onSearch() {
            if (!this.keywords) return;
            this.$router.push(`/goods/search/${encodeURIComponent(this.keywords)}`);
        },
        onDecorationData(data) {
            this.pageInfo = data.find(item => item.componentType === 'Page');
            console.log(this.pageInfo);
        },
    },
    created() {
        this.$api.Buyer.Sp.ShopDecoration.getShopIndexJson().then(json => {
            let res = json.data.data;
            this.decorationJson = res;
        });
    },
};
</script>

<style lang="scss">
</style>
