<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <div class="second-header">
            <time-select
                v-model="statTimeData"
                :all-time="allTime"
                @change="onTimeSelectChange"
            />
        </div>
        <container ref="container">
            <list
                v-for="(item,index) in dateList"
                :key="item.visitDate"
            >
                <!--<list-item
                    :label="item.visitDate"
                />-->
                <div class="visit-date">
                    {{ item.visitDate }}
                </div>
                <list
                    class="goods"
                >
                    <footprint-item
                        v-for="(goods,index2) in item.goodsList"
                        :key="goods.id"
                        :data="goods"
                        @delete="onDelete(index,index2)"
                    />
                </list>
            </list>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'Footprint',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            statTimeData: {},
            dateList: [],
        };
    },
    computed: {
        infiniteState() {
            const infinite = this.$refs['infinite'];
            return infinite ? infinite.infiniteState : null;
        },
        allTime() {
            return !!+this.$route.query.allTime;
        },
        dateMap() {
            return this.dateList.reduce((prev, curr) => {
                prev[curr.visitDate] = curr;
                return prev;
            }, {});
        },
    },
    methods: {
        buildDateList(list) {
            const { dateMap } = this;
            list.forEach(item => {
                const { visitDate } = item;
                let mapItem = dateMap[visitDate];
                if (!mapItem) {
                    mapItem = dateMap[visitDate] = {
                        visitDate,
                        goodsList: [],
                    };
                    this.dateList.push(mapItem);
                }
                mapItem.goodsList.push(item);
            });
        },
        getListData({ currentPage } = {}) {
            return this.$api.Buyer.Mb.VisitGoods.visitGoodsList({
                visitDateBegin: this.statTimeData.beginDate,
                visitDateEnd: this.statTimeData.endDate,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
            }).then(json => {
                // this.dateList = this.dateList.concat(json.data.data);
                this.buildDateList(json.data.data);
                return Promise.resolve(json);
            });
        },
        onTimeSelectChange(timeData) {
            const infinite = this.$refs['infinite'];
            const infiniteState = infinite ? infinite.infiniteState : null;
            if (!infiniteState) return;
            console.log(timeData);
            this.pagination = {};
            this.dateList = [];
            this.statTimeData = { ...timeData };
            infiniteState.reset();
        },
        onDelete(index, index2) {
            const { dateList } = this;
            const item = dateList[index];
            if (!item) return;
            const { goodsList } = item;
            if (!goodsList) return;
            goodsList.splice(index2, 1);
            if (!goodsList.length) {
                dateList.splice(index, 1);
            }
        },
    },
};
</script>

<style lang="scss">
.second-header {
    > .time-select {
        background-color: #fff;
        line-height: 0.9rem;
    }
}

.visit-date {
    font-weight: bold;
    padding: $padding-small;
}
</style>
