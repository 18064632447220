<!-- Created by henian.xu on 2019/9/17. -->

<template>
    <Page>
        <container ref="container">
            <div class="money-box">
                <div class="label">
                    账户余额
                </div>
                <div class="money price">
                    {{ accountBalance }}
                </div>
                <!--<Button
                    to="/distributor/withdraw"
                    theme="w"
                    class="tc-main"
                    radius="all"
                    size="small"
                    label="提取记录"
                />-->
            </div>
            <List>
                <ListItem
                    class="ma-b"
                    label="提现明细"
                />
                <WithdrawItem
                    v-for="item in dataList"
                    :key="item.id"
                    :data="item"
                />
            </List>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
        <PageBar>
            <WithdrawPopup
                :source="3"
                :max-amount="+accountBalance"
                @success="onWithdrawSuccess"
            >
                <Button
                    theme="main"
                    label="提现"
                />
            </WithdrawPopup>
        </PageBar>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';
import { Buyer } from '@/api';
export default {
    name: 'Income',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            distributor: {},
            withdraw: 0,
            dataList: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        Buyer.Mb.Distributor.distributorDetail()
            .then(json => {
                const res = json.data.data;
                next(vm => {
                    vm.distributor = res;
                });
            })
            .catch(() => next());
    },
    computed: {
        accountBalance() {
            const { distributor, withdraw } = this;
            return this.$options.filters.price(distributor.distributorIncome + distributor.shopStoreIncome - withdraw);
        },
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Mb.Distributor.distributorWithdrawList({
                withdrawSource: 3,
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data;
                this.dataList = this.dataList.concat(res.data);
                return Promise.resolve(json);
            });
        },
        refresh() {
            this.infiniteDisabled = true;
            this.pagination = {};
            this.dataList = [];
            this.$nextTick(() => (this.infiniteDisabled = false));
        },
        onWithdrawSuccess(val) {
            this.withdraw += +val;
            this.refresh();
        },
    },
};
</script>

<style lang="scss" scoped>
.money-box {
    //background-image: linear-gradient(mix($color-main, $color-yellow, 60%), $color-main);
    background-image: linear-gradient(160deg, #fff -500%, $color-main 70%);
    > .money,
    > .label {
        color: #fff;
    }
}
</style>
