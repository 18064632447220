<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <list>
                <list-item
                    v-for="(item) in listData"
                    :key="item.id"
                >
                    <div
                        class="label"
                        slot="label"
                    >
                        <div style="white-space: normal;">
                            {{ item.growthValueDesc }}
                        </div>
                        <div class="tc-g6">
                            {{ item.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </div>
                    </div>
                    <div
                        :class="['extra ta-r',{'tc-red':item.growthValue<0}]"
                        slot="extra"
                    >
                        {{ item.growthValue>0?'+':'' }}{{ item.growthValue }}
                    </div>
                </list-item>
            </list>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'GrowthList',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            listData: [],
        };
    },
    methods: {
        getListData({ currentPage, pageSize } = {}) {
            return this.$api.Buyer.Mb.Buyer.growthValueLogList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
                pageSize,
            }).then(json => {
                const res = json.data.data;
                this.listData = this.listData.concat(res);
                return Promise.resolve(json);
            });
        },
    },
};
</script>

<style lang="scss">
</style>
