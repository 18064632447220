<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <list class="goods">
                <collect-item
                    v-for="(item,index) in goodsList"
                    :key="item.id"
                    :data="item"
                    @delete="onDelete(item,index)"
                />
            </list>
            <infinite-loading
                ref="infinite"
                :disabled="infiniteDisabled"
                @infinite="onInfinite"
            />
        </container>
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';
import infiniteLoading from '@/mixin/infiniteLoading';

export default {
    name: 'Collect',
    mixins: [pageMixin, infiniteLoading],
    data() {
        return {
            goodsList: [],
        };
    },
    methods: {
        getListData({ currentPage } = {}) {
            return this.$api.Buyer.Mb.CollectGoods.collectGoodsList({
                currentPage: currentPage || this.pagination.currentPage ? this.pagination.currentPage + 1 : 1,
            }).then(json => {
                this.goodsList = this.goodsList.concat(json.data.data);
                return Promise.resolve(json);
            });
        },
        onDelete(item, index) {
            this.goodsList.splice(index, 1);
        },
    },
};
</script>

<style lang="scss">
</style>
