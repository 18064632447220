<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <Widget
                :id="2"
                no-show-complete
            />
            <div class="form-box">
                <x-form
                    ref="form"
                    :model="formData"
                    :rules="rules"
                >
                    <List>
                        <template v-if="$globalVar.isRegisterIHealth">
                            <form-item prop="hasIHealthAccount">
                                <div class="agreement">
                                    <Checkbox v-model="formData.hasIHealthAccount">
                                        是否有 ihlglobal账户
                                    </Checkbox>
                                </div>
                            </form-item>
                            <form-item
                                v-if="formData.hasIHealthAccount"
                                key="iHealthAccount"
                                label="ihlglobal账户："
                                prop="iHealthAccount"
                                ref="iHealthAccount"
                            >
                                <x-input
                                    type="text"
                                    clear
                                    placeholder="请输入"
                                    v-model="formData.iHealthAccount"
                                />
                            </form-item>
                            <form-item
                                v-else
                                key="email"
                                label="邮箱："
                                prop="email"
                                ref="email"
                            >
                                <x-input
                                    type="text"
                                    clear
                                    placeholder="请输入"
                                    v-model="formData.email"
                                />
                            </form-item>
                        </template>
                        <form-item
                            label="国家/地区："
                            prop="countryCodeId"
                            ref="countryCodeId"
                        >
                            <CountryCode v-model="formData.countryCodeId" />
                        </form-item>
                        <form-item
                            label="手机："
                            prop="mobile"
                            ref="mobile"
                        >
                            <!--<form-item
                                class="npa-a"
                                prop="countryCodeId">
                                <CountryCode v-model="formData.countryCodeId"/>
                            </form-item>-->
                            <x-input
                                type="text"
                                clear
                                placeholder="请输入"
                                v-model="formData.mobile"
                            />
                        </form-item>
                        <form-item
                            label="密码："
                            prop="password"
                        >
                            <x-input
                                type="password"
                                clear
                                auto-size
                                placeholder="请输入"
                                v-model="formData.password"
                            />
                        </form-item>
                        <form-item
                            label="确认密码："
                            prop="password2"
                        >
                            <x-input
                                type="password"
                                clear
                                auto-size
                                placeholder="请输入"
                                v-model="formData.password2"
                            />
                        </form-item>
                        <form-item
                            label="图片验证码: "
                            prop="jCaptcha"
                            ref="jCaptcha"
                        >
                            <x-input
                                v-model="formData.jCaptcha"
                            />
                            <div
                                class="authCode"
                                @click="onAuthCodeChange"
                            >
                                <img
                                    :src="captchaSrc"
                                    width="100%"
                                    height="100%"
                                >
                            </div>
                        </form-item>
                        <form-item
                            label="验证码："
                            prop="validateCode"
                        >
                            <x-input
                                auto-size
                                placeholder="请输入"
                                v-model="formData.validateCode"
                            />
                            <div
                                class="btn btn-main link nbor-a bor-l"
                                @click="onValidateCode"
                                v-if="!countDown"
                            >
                                获取验证码
                            </div>
                            <div
                                class="btn btn-g4 link nbor-a bor-l"
                                v-else
                            >
                                重新获取({{ countDown }}秒)
                            </div>
                        </form-item>
                        <form-item prop="agreement">
                            <div class="agreement">
                                <Checkbox v-model="formData.agreement">
                                    阅读并接受
                                </Checkbox>
                                <Linker
                                    class="tc-blue"
                                    to="/activityPage/5?footerShow&ad"
                                >
                                    《用户注册协议》
                                </Linker>
                            </div>
                        </form-item>
                    </List>
                </x-form>
                <div
                    class="btn btn-main submit"
                    @click="onSubmit"
                >
                    提交
                </div>
            </div>
        </container>
        <!--<div class="footer">
            <div
                class="btn btn-main"
                @click="onSubmit"
            >登录
            </div>
        </div>-->
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'Login',
    mixins: [pageMixin],
    data() {
        return {
            countDown: 0,
            countDownTimer: 0,
            formData: {
                countryCodeId: '',
                mobile: this.$globalVar.isDev ? '18659072807' : '',
                iHealthAccount: this.$globalVar.isDev ? 'iHealthAccount' : '',
                email: this.$globalVar.isDev ? '2818008118@qq.com' : '',
                password: this.$globalVar.isDev ? '123456abc' : '',
                rawPassword: this.$globalVar.isDev ? '123456abc' : '',
                password2: this.$globalVar.isDev ? '123456abc' : '',
                jCaptcha: '',
                validateCode: '',
                agreement: true,
                hasIHealthAccount: false,
            },
            rules: {
                mobile: [
                    {
                        required: true,
                        message: '手机号不能为空',
                    },
                    /*{
                        validator: this.$utils.Valid.mobile,
                        message: '手机号格式不正确',
                    },*/
                    {
                        validator: this.checkAccount,
                        // 远程验证 message 远程返回
                    },
                ],
                email: [
                    {
                        required: true,
                        message: '邮箱不能为空',
                    },
                    {
                        validator: this.$utils.Valid.email,
                        message: '邮箱格式不正确',
                    },
                ],
                iHealthAccount: {
                    required: true,
                    message: 'ihlglobal账户不能为空',
                },
                countryCodeId: {
                    required: true,
                    message: '区号不能为空',
                },
                password: [
                    {
                        required: true,
                        message: '密码不能为空',
                    },
                    {
                        validator: this.passwordStrengthCheck.bind(this),
                        message: '必须包含大小写字母和数字，长度不低于8位',
                    },
                ],
                password2: {
                    validator: this.$utils.Valid.againPassword.bind(this),
                    message: '两处密码不相等',
                },
                jCaptcha: {
                    required: true,
                    message: '图片验证码不能为空',
                },
                validateCode: {
                    required: true,
                    message: '短信验证码不能为空',
                },
            },
            captchaSrc: `${this.$globalVar.isDev ? 'http://192.168.1.130:8071' : ''}/rpc/comm/rs/captcha/get-captcha`,
        };
    },
    computed: {
        $$mobile() {
            return this.$refs.mobile;
        },
        $$jCaptcha() {
            return this.$refs.jCaptcha;
        },
        backUrl() {
            return this.$route.query.backUrl;
        },
    },
    beforeRouteEnter(to, from, next) {
        const backUrl = to.query.backUrl;
        if (!backUrl) {
            next({
                ...to,
                replace: true,
                query: {
                    ...to.query,
                    backUrl: from.fullPath,
                },
            });
            return;
        }
        next();
    },
    methods: {
        onAuthCodeChange() {
            this.captchaSrc = `${
                this.$globalVar.isDev ? 'http://192.168.1.130:8071' : ''
            }/rpc/comm/rs/captcha/get-captcha?_T=${Date.now()}`;
        },
        async checkAccount(rule, value, callback) {
            // callback(new Error('res.msg'));
            let res = false;
            await this.$api.Buyer.Mb.Buyer.checkAccount({
                countryCodeId: this.formData.countryCodeId,
                buyerAccount: this.formData.mobile,
            }).then(json => {
                res = json.data;
                // console.log(res);
            });
            // console.log(123);
            if (!res.success) {
                callback(new Error(res.msg));
            } else {
                callback();
            }
        },
        async passwordStrengthCheck(rule, value, callback) {
            if (!/^(?![0-9]+$)(?![a-z]+$)(?![0-9a-z]+$)(?![0-9A-Z]+$)[0-9A-Za-z\S]{8,}$/.test(value)) {
                callback(new Error(rule.message));
            } else {
                callback();
            }
        },
        onSubmit() {
            const self = this;
            if (!this.formData.agreement) {
                this.$messageBox.alert('请勾选“阅读并接受用户注册协议”');
                return;
            }
            this.$$form.validate().then(() => {
                const params = {
                    ...this.formData,
                };
                if (params.hasIHealthAccount) {
                    params.email = '';
                    params.rawPassword = '';
                } else {
                    params.iHealthAccount = '';
                    params.rawPassword = params.password;
                }
                this.$nprogress.start();
                this.$api.Buyer.Mb.Buyer.register(params)
                    .then(json => {
                        this.$nprogress.done();
                        const res = json.data;
                        this.$messageBox.tips(res.msg, {
                            callback() {
                                self.$globalVar.routerEdReplace = true;
                                // self.$router.push('/buyer/home');
                                self.$router.replace({
                                    path: `${self.backUrl || '/'}`,
                                });
                            },
                        });
                    })
                    .catch(() => {
                        this.$nprogress.done();
                    });
            });
        },
        startCountDown(val) {
            this.countDown = val;
            clearInterval(this.countDownTimer);
            this.countDownTimer = setInterval(() => {
                this.countDown--;
                if (this.countDown <= 0) {
                    this.onAuthCodeChange();
                    clearInterval(this.countDownTimer);
                    this.countDownTimer = 0;
                }
            }, 1000);
        },
        onValidateCode() {
            this.$$form.clearValidate();
            Promise.all([this.$$mobile.validate(), this.$$jCaptcha.validate()]).then(() => {
                this.$api.Comm.Rs.Sms.sendVerificationCode(this.formData).then(() => {
                    // const res = json.data.data;
                    // console.log(res);
                    this.startCountDown(60);
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.form-box {
    padding: $padding-big;
}

.logo {
    text-align: center;
    margin-top: $margin-big * 2;
    > img {
        width: 60%;
    }
}

.authCode {
    width: 2rem;
    height: 0.58rem;
    line-height: 0;
}

.submit {
    min-height: 0.9rem;
    margin-top: 0.5rem;
    width: 100%;
}

.agreement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
</style>
