<!-- Created by henian.xu on 2017/10/22. -->
<template>
    <Page>
        <container ref="container">
            <Widget
                :id="2"
                no-show-complete
            />
            <div class="form-box">
                <x-form
                    ref="form"
                    :rules="rules"
                    :model="formData"
                >
                    <list v-if="+$globalVar.memberRegisterMode===2">
                        <form-item
                            label="国家/地区："
                            prop="countryCodeId"
                            ref="countryCodeId"
                        >
                            <CountryCode v-model="formData.countryCodeId" />
                        </form-item>
                        <form-item
                            label="手机："
                            prop="mobile"
                        >
                            <x-input
                                type="text"
                                clear
                                placeholder="请输入"
                                v-model="formData.mobile"
                            />
                        </form-item>
                        <form-item
                            label="密码："
                            prop="password"
                        >
                            <x-input
                                type="password"
                                clear
                                auto-size
                                placeholder="请输入"
                                v-model="formData.password"
                                @keydown.enter="onKeyDown"
                            />
                        </form-item>
                    </list>
                    <list v-if="+$globalVar.memberRegisterMode===4">
                        <form-item
                            label="国家/地区："
                            prop="countryCodeId"
                            ref="countryCodeId"
                        >
                            <CountryCode v-model="formData.countryCodeId" />
                        </form-item>
                        <form-item
                            label="手机："
                            prop="mobile"
                            ref="account"
                        >
                            <x-input
                                type="text"
                                clear
                                placeholder="请输入"
                                v-model="formData.mobile"
                            />
                        </form-item>
                        <form-item
                            v-if="showJCaptcha"
                            label="图片验证码："
                            prop="jCaptcha"
                            ref="jCaptcha"
                        >
                            <x-input
                                type="text"
                                placeholder="请输入"
                                v-model="formData.jCaptcha"
                            />
                            <div
                                class="authCode"
                                @click="onAuthCodeChange"
                            >
                                <img
                                    class="img"
                                    :src="captchaSrc"
                                >
                            </div>
                        </form-item>
                        <form-item
                            label="验证码："
                            prop="verificationCode"
                        >
                            <x-input
                                type="text"
                                clear
                                placeholder="请输入"
                                v-model="formData.verificationCode"
                                @keydown.enter="onKeyDown"
                            />
                            <Button
                                v-if="!countDown"
                                @click="
                                    () => {
                                        onValidateCode();
                                    }
                                "
                            >
                                获取验证码
                            </Button>
                            <Button v-else>
                                重新获取({{ countDown }}秒)
                            </Button>
                        </form-item>
                    </list>
                </x-form>
                <div
                    class="btn btn-main submit"
                    @click="onSubmit"
                >
                    登录
                </div>
                <div class="dp-fx fx-row fx-jc-sb fx-ai-c ma-t tc-g6">
                    <linker
                        :to="{
                            path:'./register',
                            query:{
                                backUrl
                            }
                        }"
                    >
                        注册新用户
                    </linker>
                    <!--<div></div>-->
                    <linker
                        :to="{
                            path:'./forgotPassword',
                            query:{
                                backUrl
                            }
                        }"
                    >
                        忘记密码
                    </linker>
                </div>
            </div>
        </container>
        <!--<div class="footer">
            <div
                class="btn btn-main"
                @click="onSubmit"
            >登录
            </div>
        </div>-->
    </Page>
</template>

<script>
import pageMixin from '@/mixin/layout/page';

export default {
    name: 'Login',
    mixins: [pageMixin],
    data() {
        return {
            formData: {
                countryCodeId: '',
                mobile: this.$globalVar.isDev ? '18659072806' : '',
                password: this.$globalVar.isDev ? 'admin' : '',
                jCaptcha: '',
                verificationCode: '',
            },
            rules: {
                mobile: [
                    {
                        required: true,
                        message: '手机号不能为空',
                    },
                    /*{
                        validator: this.$utils.Valid.mobile,
                        message: '手机号格式不正确',
                    },*/
                ],
                countryCodeId: {
                    required: true,
                    message: '区号不能为空',
                },
                password: {
                    required: true,
                    message: '密码不能为空',
                },
            },
            countDown: 0,
            countDownTimer: 0,
            showJCaptcha: false,
            captchaSrc: `/rpc/comm/rs/captcha/get-captcha`,
        };
    },
    computed: {
        $$form() {
            return this.$refs.form;
        },
        backUrl() {
            return this.$route.query.backUrl;
        },
    },
    beforeRouteEnter(to, from, next) {
        const backUrl = to.query.backUrl;
        if (!backUrl) {
            next({
                ...to,
                replace: true,
                query: {
                    ...to.query,
                    backUrl: from.fullPath,
                },
            });
            return;
        }
        next();
    },
    methods: {
        async onAuthCodeChange() {
            this.captchaSrc = `/rpc/comm/rs/captcha/get-captcha?_t=${Date.now()}`;
        },
        startCountDown(val) {
            this.countDown = val;
            clearInterval(this.countDownTimer);
            this.countDownTimer = setInterval(() => {
                this.countDown -= 1;
                if (this.countDown <= 0) {
                    this.onAuthCodeChange();
                    clearInterval(this.countDownTimer);
                    this.countDownTimer = 0;
                }
            }, 1000);
        },
        async onValidateCode() {
            this.$refs.form.clearValidate();
            const promises = [this.$refs.account.validate()];
            if (this.$refs.jCaptcha) {
                promises.push(this.$refs.jCaptcha.validate());
            }
            await Promise.all(promises);
            const {
                data: { code, success, msg },
            } = await this.$api.Comm.Rs.Sms.sendVerificationCode({
                ...this.formData,
                _isHandleError: true,
            });
            if (!success) {
                if (code === 1002) {
                    this.showJCaptcha = true;
                    this.$messageBox.tips(msg);
                    return;
                }
                this.$messageBox.alert(msg, '温馨提示');
                return;
            }
            this.startCountDown(60);
        },
        onSubmit() {
            this.$$form.validate().then(() => {
                this.$api.Buyer.Mb.Buyer.login(this.formData).then(json => {
                    // const res = json.data;
                    const res = json.data;
                    if (!res.success) {
                        this.$messageBox.alert(res.msg, '温馨提示').then(() => {
                            // 当前用户已登录
                            if (res.code === 4010) {
                                // window.location.href = window.location.href.split('#')[0];
                                this.$globalVar.routerEdReplace = true;
                                this.$router.replace({
                                    path: `${this.backUrl || '/'}`,
                                });
                            }
                        });
                        return;
                    }
                    // window.location.href = window.location.href.split('#')[0];
                    this.$globalVar.routerEdReplace = true;
                    this.$router.replace({
                        path: `${this.backUrl || '/'}`,
                    });
                });
            });
        },
        onKeyDown() {
            this.onSubmit();
        },
    },
};
</script>

<style lang="scss" scoped>
.form-box {
    padding: $padding-big;
}

.logo {
    text-align: center;
    margin-top: $margin-big * 2;
    > img {
        width: 60%;
    }
}

.submit {
    min-height: 0.9rem;
    margin-top: 0.5rem;
    width: 100%;
}

.authCode {
    width: 200px;
    height: $formItemHeight;
    line-height: 0;
    > .img {
        width: 100%;
        height: 100%;
    }
}
</style>
